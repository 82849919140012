import 'construct-style-sheets-polyfill'

import { cssom, observe, twind } from '@twind/core'
import { useRouter } from 'next/router'
import type { ReactNode, RefObject } from 'react'
import { useEffect, useRef } from 'react'
import ReactShadowRoot from 'react-shadow-root'
import type _default from 'react-shadow-root/lib/ReactShadowRoot'

import config from '@/../twind.config'
import { getFooterProps, getHeaderProps } from '@/common/components/layout/mappers'
import { openNewsletterFooter, openNewsletterHeader, PopUps } from '@/common/components/popups'
import { ModalTriggerEvent, ModalTriggerTypes } from '@/events/ModalTriggerEvent'
import type { LayoutProps } from '@/sanity/services/common/getCommonData'
import { ModalTypes } from '@/sanity/types'

import { DzFooter } from '../wrappers/DzFooterWrapper'
import { DzHeader } from '../wrappers/DzHeaderWrapper'
import { openArtworkInquiry } from './popups'

type StaticPageLayout = (props: {
  children: ReactNode
  pageType?: string
  layoutData: LayoutProps['layoutData']
}) => ReactNode

// Avoid leaking global styles within the shadow root
const shadowStyles = `:host { all: inherit; }`

// Observe tailwind styles and inject them to the Shadow DOM as constructable stylesheets
const addStylesheet = async (shadowContainerId: string) => {
  const sheet = cssom(new CSSStyleSheet())
  const twin = twind(config, sheet)
  const shadowRoot = document.querySelector(shadowContainerId)?.shadowRoot

  if (shadowRoot) {
    const nav = new CSSStyleSheet()
    // Fonts were overwritten by global stylesheets
    nav.replaceSync(`
      * {
        font-family: avenir-medium,avenir-medium-italic,avenir-regular,sans-serif
      }
    `)

    // .h-dvh was recently added to Tailwind. Not present on the tailwind presets for twind. Used for popups
    nav.replaceSync('.h-dvh { height: 100dvh; }')

    // .max-w-10 is not supported by twind. Used for popups
    nav.replaceSync('.max-w-40 { max-width: 10rem; }')

    shadowRoot.adoptedStyleSheets = [sheet.target, nav]
    observe(twin, shadowRoot)
  }
}

const StaticPageLayoutShadow: StaticPageLayout = ({ children, layoutData, pageType }) => {
  const router = useRouter()
  const { menu } = getHeaderProps(layoutData)
  const { data } = getFooterProps(layoutData.footerData)
  const shadowRootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    addStylesheet('#shadowContainer')
    addStylesheet('#shadowContainerModal')
    addStylesheet('#shadowContainerFooter')
  }, [])

  useEffect(() => {
    const open = () => {
      window.document.dispatchEvent(
        ModalTriggerEvent({
          modalType: ModalTypes.NEWSLETTER,
          triggerType: ModalTriggerTypes.POPUP,
          props: {},
        })
      )
    }

    document.addEventListener('open-subscribe-popup', open)
    document.addEventListener('open-inquire-popup', openArtworkInquiry)
    document.addEventListener('open-artwork-overlay', openArtworkInquiry)

    return () => {
      document.removeEventListener('open-subscribe-popup', open)
      document.removeEventListener('open-inquire-popup', openArtworkInquiry)
      document.removeEventListener('open-artwork-overlay', openArtworkInquiry)
    }
  }, [])

  return (
    <>
      <div id="shadowContainer" className="sticky top-0 z-10 w-screen">
        <ReactShadowRoot>
          <style>{shadowStyles}</style>
          <DzHeader
            menu={menu}
            footerData={data as any}
            headerClass="sticky top-0"
            linkProps={{ router, useRoute: true }}
            newsletterAction={openNewsletterHeader}
            collections={undefined}
            hasCart={false}
          />
        </ReactShadowRoot>
      </div>

      <div id="shadowContainerModal">
        <ReactShadowRoot ref={shadowRootRef as unknown as RefObject<_default>}>
          <style>{shadowStyles}</style>
          <PopUps
            router={router}
            pageType={pageType}
            shadowRootRef={shadowRootRef}
            usePortalForRecaptcha
          />
        </ReactShadowRoot>
      </div>

      {children}

      <div id="shadowContainerFooter">
        <ReactShadowRoot>
          <style>{shadowStyles}</style>
          {/* remove Shopify Hydrogen */}
          {/* <CartPanel /> */}

          <DzFooter
            footerClass="relative bottom-0"
            data={data as any}
            newsletterAction={openNewsletterFooter}
          />
        </ReactShadowRoot>
      </div>
    </>
  )
}

export default StaticPageLayoutShadow
