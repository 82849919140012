import axios from 'axios'
import axiosRetry from 'axios-retry'
import nodeParser from 'node-html-parser'

import { env } from '@/env.mjs'

// this amount of retries and delay is huge, we should consider reducing it
axiosRetry(axios, {
  retries: 16,
  retryDelay: (attempt) => Math.min(100 * 2 ** attempt, 32000) + Math.random() * 1000,
})

export enum S3RootFolder {
  Desktop = '/desktop',
  Mobile = '/mobile',
}

/**
 * This function should be called only on Server Side.
 * Every exported page have 2 versions, one for desktop and one for mobile.
 * Routes in CMS point to the desktop version by default, but we can serve the mobile version by changing the root folder.
 *
 * @param url - URL to fetch
 * @returns HTML string
 */
export const getHtml = async (
  url: string,
  s3RootFolder: S3RootFolder
): Promise<{
  body: string
  head?: string
  scripts?: { src: string; async: boolean; inlineJs: string }[]
}> => {
  const parsedUrl = url.startsWith('/') ? url : `/${url}`
  const fullHtmlUrl = `${env.NEXT_PUBLIC_STATIC_HOST}${s3RootFolder}${parsedUrl}`

  return await axios
    .get(fullHtmlUrl)
    .then((response) => {
      const html = nodeParser.parse(response.data)
      const head = html.querySelector('head')
      head?.remove()
      const scripts = html.querySelectorAll('script')
      const mappedScripts = scripts.map((script) => ({
        src: script.getAttribute('src') || '',
        async: script.hasAttribute('async'),
        inlineJs: script.innerHTML,
      }))
      const body = html.toString()
      if (!body) throw new Error('Body not found in the HTML')
      return { head: head?.toString(), body, scripts: mappedScripts }
    })
    .catch((error) => {
      console.error('Error fetching HTML from url:', url)
      throw new Error('Error fetching HTML from S3', { cause: error })
    })
}
